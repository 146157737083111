<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{
              name: 'DashboardHome',
            }">
              <i class="fa fa-home"></i>
              銷售應用中心
            </b-breadcrumb-item>
            <b-breadcrumb-item active>商品列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <b-overlay :show="showLoading" rounded="sm">
        <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
          <h4 class="col-12 col-xl-2 mb-1 mb-xl-0 font-weight-bold">
            商品列表
          </h4>
          <div class="
              col-12 col-xl-6
              d-flex
              flex-column flex-xl-row
              align-items-start align-items-xl-center
            ">
            <b-button class="flex-shrink-0 mb-2 mb-xl-0 mr-3 py-2" variant="primary" :to="{
              name: 'ProductCreate',
              query: { merchant_id: this.selectedMerchantID },
            }"><i class="fa fa-plus" aria-hidden="true"></i>新增商品
            </b-button>
            <b-form-select v-model="selectedMerchantID" :options="merchants" :disabled="merchants.length == 2"
              @change="handleSelectMerchant"></b-form-select>
            <b-input-group class="ml-0 ml-xl-4">
              <b-form-input placeholder="搜尋商品" v-model="keyword" v-on:keyup.enter="fetchProducts"></b-form-input>
              <b-input-group-append>
                <b-button @click="fetchProducts"><i class="fa fa-search"></i></b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
        <b-table responsive striped hover :items="products" :fields="fields">
          <template #cell(name)="data">
            <router-link :to="{
              name: 'ProductDetail',
              params: {
                product_id: data.item.id,
              },
            }">
              <div class="mb-1">{{ data.item.title }}</div>
              <div>{{ data.item.sale_page_code }}</div>
            </router-link>
          </template>
          <template #cell(picture)="data">
            <b-img-lazy v-if="data.item.images[0] &&
              data.item.images[0].pic_url
              " class="thumb-image" :src="data.item.images[0] &&
    data.item.images[0].pic_url
    " alt="商品圖片" width="50" height="50" />
          </template>
          <template #cell(started_at_and_end_at)="data">
            <div class="mb-1">{{ formatDate(data.item.available_start_at) }}</div>
            <div>{{ formatDate(data.item.available_end_at) }}</div>
          </template>
          <template #cell(branch)="data">
            <div class="mb-1">
              {{ data.item.branch ? data.item.branch.name : "" }}
            </div>
            <div>
              {{ data.item.branch ? data.item.branch.branch_code : "" }}
            </div>
          </template>
          <template #cell(arrange)="data">
            <b-button class="ml-2" variant="primary" v-if="checkPermission([consts.PRODUCT_LIST_VIEW])" :to="{
              name: 'ProductDetail',
              params: {
                product_id: data.item.id,
              },
            }">查看
            </b-button>
            <b-button class="ml-2" variant="inverse-info" v-if="checkPermission([consts.PRODUCT_IMAGE_MODIFY])" :to="{
              name: 'ProductUpdateImage',
              params: {
                product_id: data.item.id,
              },
            }">設定圖片
            </b-button>
            <b-button class="ml-2" variant="inverse-warning" v-if="checkPermission([consts.PRODUCT_MODIFY])" :to="{
              name: 'ProductEdit',
              params: {
                product_id: data.item.id,
              },
            }">編輯
            </b-button>
            <b-button class="ml-2" variant="inverse-danger" @click="handleDelete(data.item.id)"
              v-if="checkPermission([consts.PRODUCT_DELETE])">刪除
            </b-button>
          </template>
        </b-table>
        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center"></b-pagination>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>

import merchantApi from "../../../apis/merchant";
import productApi from "../../../apis/product";
import moment from "moment";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";

export default {
  data() {
    return {
      consts,
      keyword: null,
      showLoading: false,
      selectedMerchantID: null,
      merchants: [
        { value: null, text: "請選擇" },
      ],
      currentPage: 1,
      totalRows: 1,
      perPage: 10,
      fields: [
        {
          key: "name",
          label: "商品名稱/編號",
        },
        {
          key: "picture",
          label: "商品圖片",
        },
        {
          key: "price",
          label: "售價/原價",
        },
        {
          key: "started_at_and_end_at",
          label: "開始/結束販售時間",
        },
        {
          key: "status",
          label: "商品狀態",
        },
        {
          key: "branch",
          label: "分店名稱/ID",
        },
        {
          key: "is_closed",
          label: "是否關閉",
        },
        {
          key: "type",
          label: "商品類型",
        },
        {
          key: "arrange",
          label: "管理",
        },
      ],
      products: [],
      permissionChecker: new PermissionChecker(),
    };
  },
  watch: {
    currentPage: function () {
      this.fetchProducts()
    }
  },
  mounted() {
    this.fetchMerchants()
  },
  methods: {
    async fetchProducts() {
      if (!this.selectedMerchantID) return

      this.showLoading = true;

      try {
        const params = {
          page: this.currentPage,
        };
        if (this.keyword) params.keyword = this.keyword;
        if (this.selectedMerchantID) params.merchant_id = this.selectedMerchantID

        const { data } = await productApi.getProducts(params);

        this.products = data.data;
        this.totalRows = data.meta.total;
        this.perPage = data.meta.per_page;
      } catch (error) {
        console.error(error);
        alert("取得商品資料錯誤");
      }
      this.showLoading = false;
    },
    async fetchMerchants() {
      const { data } = await merchantApi.list();

      this.merchants = [
        { value: null, text: "請選擇" },
        ...data.data.map((merchant) => {

          return {
            value: merchant.id,
            text: `${merchant.name} (${merchant.type_name})`,
          };
        }),
      ];

      let defaultMerchant = this.findDefaultMerchant(data.data)

      if (defaultMerchant) {
        this.selectedMerchantID = defaultMerchant.id
        this.fetchProducts()
      }
    },
    findDefaultMerchant(merchants) {
      let defaultMerchant

      if (this.$route.query.merchant_id) {
        defaultMerchant = merchants.find((merchant) => {
          return merchant.id === this.$route.query.merchant_id
        })

        if (!defaultMerchant) {
          this.$swal
            .fire({
              type: "warning",
              title: "查無此通路",
              confirmButtonText: "確定",
              confirmButtonColor: "#d33",
              reverseButtons: true,
            })
          return
        }
      } else if (merchants.length == 1) {
        defaultMerchant = merchants[0]
      } else {
        defaultMerchant = merchants.filter(merchant => merchant.type == 'LINE').shift()
      }

      return defaultMerchant
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:mm");
      }
    },
    async handleDelete(id) {
      this.$swal
        .fire({
          type: "warning",
          title: "你確定要刪除嗎？",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              const response = await productApi.deleteProduct(id);
              if (response.status === 200) {
                await this.$swal.fire({
                  title: "成功",
                  type: "success",
                  text: "刪除成功",
                });
                await this.fetchProducts();
              }
            } catch (error) {
              this.$swal("失敗", error.response.data.message, "error");
            }
          }
        });
    },
    checkPermission(permissions) {
      return this.permissionChecker.check(permissions);
    },
    handleSelectMerchant() {
      this.$router.replace({ query: { merchant_id: this.selectedMerchantID } })

      this.fetchProducts()
    }
  },
};
</script>

<style>
.modal-backdrop {
  opacity: 0.5;
}
</style>
